import { render, staticRenderFns } from "./CounsellorPointsPredict.vue?vue&type=template&id=4be25fdb&scoped=true&"
import script from "./CounsellorPointsPredict.vue?vue&type=script&lang=js&"
export * from "./CounsellorPointsPredict.vue?vue&type=script&lang=js&"
import style0 from "./CounsellorPointsPredict.vue?vue&type=style&index=0&id=4be25fdb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be25fdb",
  null
  
)

export default component.exports