<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22" class="component-title" v-if="!isMobile()">
                Student Subjects: <strong>{{$store.state.counsellorStudentName}}</strong>
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row class="counsellor-subject-div">
            <el-row>
                <div class="caoPrediction caoPrediction-points">
                    Predicted CAO Points for <u>{{$store.state.counsellorStudentName}}:</u>
                    <span style="font-weight: 600; font-family: Niramit, serif !important;">
                        {{$store.state.predictedCAOPoints}}
                    </span>
                </div>
            </el-row>
            <!--            <el-row :gutter="40">-->
            <!--                <el-col :lg="11" :md="11" :sm="24" :xl="11" :xs="24">-->
            <!--                    <div style="text-align: justify; margin: 2vh 0;">-->
            <!--                        <h4>Subject</h4>-->
            <!--                    </div>-->
            <!--                </el-col>-->
            <!--                <el-col :lg="5" :md="5" :xl="5" :sm="12" :xs="12" v-if="!isMobile()">-->
            <!--                    <div style="text-align: justify; margin: 2vh 0;">-->
            <!--                        <h4>Level</h4>-->
            <!--                    </div>-->
            <!--                </el-col>-->
            <!--                <el-col :lg="5" :md="5" :xl="5" :sm="12" :xs="12" v-if="!isMobile()">-->
            <!--                    <div style="text-align: justify; margin: 2vh 0;">-->
            <!--                        <h4>Grade</h4>-->
            <!--                    </div>-->
            <!--                </el-col>-->
            <!--            </el-row>-->
            <el-row :gutter="40" class="subjectRow">
                <el-col :span="7" v-for="(subjectItem, index) in getStudentSubjects" :key="index">
                    <el-card :body-style="{ padding: '0px' }" style="margin-top: 1vh;">
                        <div style="padding: 14px;">
                            <div class="subject-name">
                                {{getSubjectName(subjectItem.subject)}}
                            </div>
                            <!--                            <div class="bottom clearfix">-->
                            <!--                                <div class="select-subject-display" v-if="subjectItem.level !== ''">-->
                            <!--                                    {{getLevel(subjectItem.level)}}-->
                            <!--                                </div>-->
                            <!--                                <div class="select-subject-display" v-else>-->
                            <!--                                    - -->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="grade-name">
                                {{getGradeLabel(subjectItem.overall)}}
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import SelectSubjects from '../../main_components/SelectSubjects';

  export default {
    name: 'CounsellorPointsPredict',
    components: {SelectSubjects, ProfileAvatar},
    props: ['studentId', 'studentName'],
    mounted() {
      let self = this;
      self.checkUserLoggedIn().then(response => {
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        if (self.studentId === undefined || self.studentName === undefined) {
          self.$router.push('/counsellor/home/');
        }
        self.$store.state.counsellorStudentId = self.studentId;
        self.$store.state.counsellorStudentName = self.studentName;
        self.getPredictedCAOPoints(self.studentId).then(response1 => {
          self.fetchStudentSubjects();
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getStudentSubjects: function() {
        return this.studentSubjects;
      },
    },
    data: function() {
      return {
        loadingScreen: false,
        studentCAOPoints: '',
        studentSubjects: [],
        gradeMapping: {
          'O1': 'O1 (90-100%)',
          'O2': 'O2 (80-89%)',
          'O3': 'O3 (70-79%)',
          'O4': 'O4 (60-69%)',
          'O5': 'O5 (50-59%)',
          'O6': 'O6 (40-49%)',
          'O7': 'O7 (30-39%)',
          'O8': 'O8 (0-30%)',
          'H1': 'H1 (90-100%)',
          'H2': 'H2 (80-89%)',
          'H3': 'H3 (70-79%)',
          'H4': 'H4 (60-69%)',
          'H5': 'H5 (50-59%)',
          'H6': 'H6 (40-49%)',
          'H7': 'H7 (30-39%)',
          'H8': 'H8 (0-30%)',
          'F1': 'F1',
          'F2': 'F2',
          '1': 'DISTINCTION',
          '2': 'MERIT',
          '3': 'PASS',
          'DISTINCTION': 'DISTINCTION',
          'MERIT': 'MERIT',
          'PASS': 'PASS',
        },
      };
    },
    methods: {
      getSubjectName: function(subjectName) {
        return subjectName.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getLevel: function(level) {
        return level.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getGradeLabel: function(grade) {
        return this.gradeMapping[grade.toUpperCase()].toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      fetchStudentSubjects: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/subjects/' + self.studentId + '/';
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.studentSubjects = response;
          },
          error: function(error) {
            console.log(error);
          },
        });

      },
    },
  };
</script>

<style scoped>
    .subjectRow {
        padding: 0.4vh 0.4vw;
    }

    .subjectRow:nth-child(even) {
        background-color: #FDF6EF;
    }

    .subjectRow:nth-child(odd) {
        background-color: #ffffff;
    }
</style>
